<template>
  <b-card>
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form class="mt-1" @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Studi Kasus
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="nisn" rules="required">
                <b-form-group label="Siswa" label-for="nisn" :state="errors.length > 0 ? false : null">
                  <v-select id="nisn" v-model="dataParent.nisn" :reduce="(nisn) => nisn.nisn" placeholder="Pilih Siswa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Tanggal" label-for="date">
                <validation-provider #default="{ errors }" name="Tanggal" rules="required">
                  <b-form-input id="date" v-model="dataParent.date" type="date" :state="errors.length > 0 ? false : null"
                    placeholder="21-05-1992" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Gejala Masalah" label-for="problem">
                <validation-provider #default="{ errors }" name="Masalah" rules="required">
                  <b-form-input id="problem" v-model="dataParent.problem" :state="errors.length > 0 ? false : null"
                    placeholder="Masalah" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>              
              <validation-provider #default="{ errors }" name="background" rules="required">
                <b-form-group label="Latar Belakang" label-for="background" :state="errors.length > 0 ? false : null">
                  <v-select id="background" v-model="dataParent.background" :reduce="(background) => background.real_id"
                    placeholder="Pilih Siswa" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="listBackground" label="background_desc" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>              
            </b-col>
            <b-col md="6">
              <b-form-group label="Perkiraan Masalah" label-for="estimated_problem">
                <validation-provider #default="{ errors }" name="estimated_problem" rules="required">
                  <b-form-input id="estimated_problem" v-model="dataParent.estimated_problem"
                    :state="errors.length > 0 ? false : null" placeholder="Perkiraan Masalah" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Penanganan" label-for="handling">
                <validation-provider #default="{ errors }" name="Penanganan" rules="required">
                  <b-form-input id="handling" v-model="dataParent.handling" :state="errors.length > 0 ? false : null"
                    placeholder="Penanganan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Tindak Lanjut" label-for="follow_up">
                <validation-provider #default="{ errors }" name="follow_up" rules="required">
                  <b-form-input id="follow_up" v-model="dataParent.follow_up" :state="errors.length > 0 ? false : null"
                    placeholder="Tindak Lanjut" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider #default="{ errors }" name="bk_teacher" rules="required">
                <b-form-group label="Guru BK" label-for="bk_teacher" :state="errors.length > 0 ? false : null">
                  <v-select id="bk_teacher" v-model="dataParent.bk_teacher" :reduce="(bk_teacher) => bk_teacher.id"
                    placeholder="Pilih Guru BK" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listGuru"
                    label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <!-- submit and reset -->
            <b-col offset-md="4">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                @click="() => $router.go(-1)">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      listSiswa: [],
      listGuru: [],
      listBackground: [],
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        nisn: '',
        date: '',
        problem: '',
        background: '',
        estimated_problem: '',
        handling: '',
        follow_up: '',
        bk_teacher: '',
      }
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  created() {
    this.getsiswa(),
    this.getGuru(),
    this.getBackgorund()
  },
  methods: {
    async getBackgorund() {
      try {
        const response = await this.$http.get('/casesstudiesbackgrounds')
        this.listBackground = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getGuru() {
      try {
        const response = await this.$http.get('/employees')
        this.listGuru = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        await this.$http.post('/studentcasestudies', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-studi-kasus' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
